import Vue from 'vue';
import Router from 'vue-router';
import Index from './views/Index.vue';
import MainNavbar from './layout/MainNavbar.vue';
import Navbar from './layout/Navbar.vue';
import MainFooter from './layout/MainFooter.vue';
import Register from './views/Register';
import Faqs from './views/Faqs';
import Page404 from './views/404';

Vue.use(Router);

export default new Router({
    mode: 'history',
    linkExactActiveClass: 'active',
    routes: [
        {
            path: '/',
            name: 'index',
            components: { default: Index, header: MainNavbar, footer: MainFooter },
            props: {
                header: { colorOnScroll: 400 },
                footer: { backgroundColor: 'black' }
            }
        },
        {
            path: '/404',
            name: '404',
            meta: { isPublic: true },
            component: Page404,
            alias: '*'
        },
        {
            path: '/register',
            name: 'register',
            components: { default: Register, header: Navbar, footer: MainFooter },
            props: {
                header: { colorOnScroll: 400 },
                footer: { backgroundColor: 'black' }
            }
        },
        {
            path: '/faqs',
            name: 'faqs',
            components: { default: Faqs, header: Navbar, footer: MainFooter },
            props: {
                header: { colorOnScroll: 400 },
                footer: { backgroundColor: 'black' }
            }
        }
    ],
    scrollBehavior: to => {
        if (to.hash) {
            return { selector: to.hash };
        } else {
            return { x: 0, y: 0 };
        }
    }
});
