import axios from 'axios';
import config from '@/config/env-constants';

export default {
    baseUrl: config.getBaseUrl(config.currEnv),
    localUrl: 'http://localhost:5001/elog-dev-app/us-central1',
    addUser(params) {
        const url = `${this.baseUrl}/addUser`;
        return axios.post(url, { fromAuth: 'false', user: JSON.stringify(params) });
    },
    validateUserDetails(params) {
        const url = `${this.baseUrl}/validateUserDetails`;
        return axios.post(url, params);
    }
}