import Vue from 'vue';
import App from './App.vue';
import router from './router';
import NowUiKit from './plugins/now-ui-kit';
import Embed from 'v-video-embed';
import '@fortawesome/fontawesome-free/css/all.css';
import '@fortawesome/fontawesome-free/js/all.js';
import store from './store';
import VueMq from 'vue-mq';
import { categoryFormatter } from './utils/string';

Vue.config.productionTip = false;

Vue.use(NowUiKit);
Vue.use(Embed);
Vue.use(VueMq, {
    breakpoints: {
        mobile: 450,
        tablet: 900,
        laptop: 1250,
        desktop: Infinity
    }
});

// Filters
Vue.filter('categoryFormatter', (str) => {
  if (!str) return '';
  return categoryFormatter(str);
});

new Vue({
    store,
    router,
    render: h => h(App)
}).$mount('#app');
