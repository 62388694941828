<template>
  <navbar position="fixed" type="white" :color-on-scroll="colorOnScroll" menu-classes="ml-auto">
    <template>
      <router-link class="navbar-brand py-0" to="/">
        <img :src="navbarBrandImage" class="mt-0" :height="`${$mq === 'desktop' ? 80 : 45}`" alt="Home Icon" />
      </router-link>
    </template>
    <template slot="navbar-menu">
      <li class="nav-item">
        <router-link :class="extraNavClass" class="nav-link" id="nav1" to="/">
          <p style="font-size: 0.8rem">Home</p>
        </router-link>
      </li>
      <li class="nav-item">
        <a :class="extraNavClass" class="nav-link" id="nav2" href="/#features">
          <p style="font-size: 0.8rem">Features</p>
        </a>
      </li>
      <li class="nav-item">
        <a :class="extraNavClass" class="nav-link" id="nav3" href="/#elog">
          <p style="font-size: 0.8rem">eLog</p>
        </a>
      </li>
      <li class="nav-item">
        <a :class="extraNavClass" class="nav-link" id="nav4" href="/#hub-guard">
          <p style="font-size: 0.8rem">Hub Guard</p>
        </a>
      </li>
      <li class="nav-item">
        <a :class="extraNavClass" class="nav-link" id="nav5" href="/#qr-code">
          <p style="font-size: 0.8rem">QR Code</p>
        </a>
      </li>
      <li class="nav-item">
        <a :class="extraNavClass" class="nav-link" id="nav6" href="/#user-reviews">
          <p style="font-size: 0.8rem">Our User</p>
        </a>
      </li>
      <li class="nav-item">
        <a :class="extraNavClass" class="nav-link" id="nav7" href="/#contact-us">
          <p style="font-size: 0.8rem">Contact Us</p>
        </a>
      </li>
      <li class="nav-item">
        <router-link :class="`${extraNavClass} ${$mq === 'desktop' ? 'btn-light px-3' : ''
          }`" class="nav-link btn-round" id="nav8" to="/register">
          <p style="font-size: 0.8rem">Register</p>
        </router-link>
      </li>
    </template>
  </navbar>
</template>

<script>
import { DropDown, NavbarToggleButton, Navbar, NavLink } from "@/components";
import { Popover } from "element-ui";
import { mapGetters } from "vuex";

export default {
  name: "main-navbar",
  props: {
    transparent: Boolean,
    colorOnScroll: Number,
  },
  components: {
    DropDown,
    Navbar,
    NavbarToggleButton,
    NavLink,
    [Popover.name]: Popover,
  },
  data() {
    return {
      navbarBrandImage: "images/optimized/elog-logo-white.svg",
      extraNavClass: "",
    };
  },
  computed: {
    ...mapGetters("app", ["getCurrentScrollValue"]),
    currentRoutePath() {
      return this.$route.fullPath;
    },
  },
  watch: {
    getCurrentScrollValue: {
      handler(value) {
        this.setNavbarBrand(value);
      },
      immediate: true,
    },
  },
  methods: {
    setNavbarBrand(currentScrollValue) {
      if (currentScrollValue >= 400) {
        this.navbarBrandImage = "images/optimized/elog-logo-colored.svg";
        this.extraNavClass = "";
      } else {
        this.navbarBrandImage = "images/optimized/elog-logo-white.svg";
        this.extraNavClass = "text-white";
      }

      this.$nextTick(() => this.setActiveButton(this.currentRoutePath));
    },
    setActiveButton(routeName) {
      let color = "";
      if (this.extraNavClass === "text-white") {
        color = "white";
      } else {
        color = "#479AC8";
      }

      let style = `3px solid ${color}`;
      const home = document.getElementById("nav1");
      const features = document.getElementById("nav2");
      const elog = document.getElementById("nav3");
      const hubGuard = document.getElementById("nav4");
      const qrCode = document.getElementById("nav5");
      const userReviews = document.getElementById("nav6");
      const contactUs = document.getElementById("nav7");
      const register = document.getElementById("nav8");

      const links = [
        { path: "/", element: home },
        { path: "/#features", element: features },
        { path: "/#elog", element: elog },
        { path: "/#hub-guard", element: hubGuard },
        { path: "/#qr-code", element: qrCode },
        { path: "/#user-reviews", element: userReviews },
        { path: "/#contact-us", element: contactUs },
        { path: "/register", element: register },
      ];

      for (let i = 0; i < links.length; i++) {
        const link = links[i];
        if (routeName === link.path) {
          link.element.style.borderBottom = style;
        } else {
          link.element.style.borderBottom = "";
        }
      }
    },
  },
};
</script>

<style scoped>
.btn-round {
  border-radius: 12px;
  color: #4599c0 !important;
}
</style>