<template>
  <div>
    <hero />
    <features />
    <about />
    <steps />
    <hub-guard />
    <q-r-code />
    <UserReview />
    <ContactUs />
  </div>
</template>

<script>
import Hero from "./components/Hero";
import About from "./components/About";
import Features from "./components/Features";
import Steps from "./components/Steps";
import HubGuard from "./components/HubGuard";
import QRCode from "./components/QRCode";
import UserReview from "./components/UserReview";
import ContactUs from "./components/ContactUs";

export default {
  name: "index",
  bodyClass: "index-page",
  components: {
    Hero,
    Features,
    About,
    Steps,
    HubGuard,
    QRCode,
    UserReview,
    ContactUs,
  },
};
</script>
