<template>
  <modal :show.sync="getDialogProps.isOpen" :class="`modal-${getDialogProps.status === 'success' ? 'primary' : 'danger'
    }`" :show-close="false" headerClasses="justify-content-center" type="mini">
    <div slot="header" class="modal-profile">
      <i :class="`now-ui-icons ${getDialogProps.status === 'success'
          ? 'ui-1_check'
          : 'ui-1_simple-remove text-danger'
        }`"></i>
    </div>
    <p>{{ getDialogProps.message }}</p>
    <div class="text-right" slot="footer" style="width: 100%">
      <n-button type="neutral" link @click.native="$store.commit('alert/CLOSE_DIALOG')">Close</n-button>
    </div>
  </modal>
</template>
<script>
import { Button, Modal } from "@/components";
import { mapGetters } from "vuex";

export default {
  name: "alert-dialog",
  components: {
    Modal,
    [Button.name]: Button,
  },
  computed: mapGetters("alert", ["getDialogProps"]),
};
</script>
<style lang="scss">
#javascriptComponents {

  .modal-buttons,
  .popover-buttons,
  .tooltip-buttons {
    .btn+.btn {
      margin-left: 3px;
    }
  }
}
</style>