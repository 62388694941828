<template>
  <div class="card card-plain">
    <div role="tab" id="headingOne" class="card-header">
      <a
        class="title"
        data-toggle="collapse"
        data-parent="#accordion"
        :href="`#${itemId}`"
        @click.prevent="activate"
        :aria-expanded="active"
        :aria-controls="`content-${itemId}`"
      >
        <slot name="title">
          {{ title }} <span class="badge badge-pill badge-primary" style="font-size: 8px; padding: 2px 9px; margin-bottom: 0px;">{{ titleBadge }}</span>
        </slot>
        <slot name="icon">
          <i v-if="!noIcon" class="now-ui-icons arrows-1_minimal-down"></i>
        </slot>
      </a>
    </div>
    <collapse-transition :duration="animationDuration">
      <div
        v-show="active"
        :id="`content-${itemId}`"
        role="tabpanel"
        :aria-labelledby="title"
        class="collapsed"
      >
        <div class="card-body pt-2 pb-0">
          <slot></slot>
        </div>
      </div>
    </collapse-transition>
  </div>
</template>
<script>
import { CollapseTransition } from 'vue2-transitions';
import { Badge } from '../index';

export default {
  name: 'collapse-item',
  components: {
    Badge,
    CollapseTransition
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    id: String,
    noIcon: Boolean,
    titleBadge: String
  },
  inject: {
    animationDuration: {
      default: 250
    },
    multipleActive: {
      default: false
    },
    addItem: {
      default: () => {}
    },
    removeItem: {
      default: () => {}
    },
    deactivateAll: {
      default: () => {}
    }
  },
  computed: {
    itemId() {
      return this.id || this.title;
    }
  },
  data() {
    return {
      active: false
    };
  },
  methods: {
    activate() {
      if (!this.multipleActive) {
        this.deactivateAll();
      }
      this.active = !this.active;
    }
  },
  mounted() {
    this.addItem(this);
  },
  destroyed() {
    if (this.$el && this.$el.parentNode) {
      this.$el.parentNode.removeChild(this.$el);
    }
    this.removeItem(this);
  }
};
</script>
<style scoped>
.title {
  text-decoration: none !important;
  color: #2c2c2c !important;
  font-weight: bold !important;
  font-size: 18px;
}
</style>
