<template>
  <div class="section" id="instructions" style="background-color: #faf9ff">
    <img v-if="$mq === 'desktop'" class="img-fluid left-side-prop" src="images/steps/optimized/steps-graphic.svg"
      alt="image" />

    <div class="container-fluid">
      <div class="row text-center">
        <div class="col-md-12">
          <h2 class="font-weight-bold">How does eLog work?</h2>
        </div>
      </div>
      <div class="row justify-content-center">
        <div :class="`col-md-2 col-sm-12 mt-4`" v-for="(step, index) in steps" :key="index">
          <img :src="step.img" class="img-fluid" alt="img" />
          <p class="font-weight-bold mt-3">{{ step.title }}</p>
          {{ step.instruction }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "steps",
  data() {
    return {
      steps: [
        {
          img: "images/steps/optimized/1-download-the-app.png",
          title: "1. Download the app and install",
          instruction:
            "Go to Google Playstore, search and download eLog - Digital Logging Application",
        },
        {
          img: "images/steps/optimized/2-create-your-account.png",
          title: "2. Create your account",
          instruction:
            "No account yet? Click REGISTER (Skip this step if you have existing account). Fill out all fields and click REGISTER",
        },
        {
          img: "images/steps/optimized/3-login.png",
          title: "3. Login using your registered email and password",
          instruction: "",
        },
        {
          img: "images/steps/optimized/4-scan-qr-code.png",
          title: "4. Scan QR Code to check in and check out",
          instruction: "Just scan qr code in your designated areas",
        },
        {
          img: "images/steps/optimized/5-check-in-successful.png",
          title: "5. Check in successful!",
          instruction:
            "Now you have successfully checked in and your log will be recorded and uploaded to an online database",
        },
      ],
    };
  },
};
</script>

<style scoped>
.left-side-prop {
  margin-top: 130px;
  width: 300px;
  position: absolute;
  right: 0px;
}
</style>