export const categoryFormatter = (str) => {
  var i, frags = str.split('_');
  for (i=0; i<frags.length; i++) {
    frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
  }
  return frags.join(' ');
}

export const truncate = (str, n) => {
  return (str.length > n) ? str.substr(0, n-1) + '...' : str;
}