export default {
    state: {
        currentScrollValue: 0
    },

    getters: {
        getCurrentScrollValue(state) {
            return state.currentScrollValue;
        }
    },

    mutations: {
        SET_CURRENT_SCROLL_VALUE(state, value) {
            state.currentScrollValue = value;
        }
    },

    actions: {

    }
}