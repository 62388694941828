import axios from 'axios';
import config from '@/config/env-constants';

export default {
    baseUrl: config.getBaseUrl(config.currEnv),
    getFaqs(withTrashed) {
        const url = `${this.baseUrl}/getFaqs`;
        return axios.get(url, {params: { withTrashed }});
    },
    createFaq(params) {
        const url = `${this.baseUrl}/createFaq`;
        return axios.post(url, params);
    }
}