var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"section"},[_c('loading',{attrs:{"active":_vm.isLoading,"loader":"spinner","color":"#4599C0","is-full-page":true},on:{"update:active":function($event){_vm.isLoading=$event}}}),_c('div',{staticClass:"container pt-2"},[_vm._m(0),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-6 ml-auto mr-auto col-md-8"},[_c('ValidationObserver',{ref:"form"},[_c('div',{staticClass:"row mb-3"},[_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"name":"First Name","rules":{
                required: true,
                regex: /^[ÑA-Za-z][ñA-Za-z- ]{1,30}$/,
              }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('label',{attrs:{"for":"first_name"}},[_vm._v("First Name")]),_c('span',{staticClass:"form-text text-danger label-hint"},[_vm._v(_vm._s(errors[0]))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.firstName),expression:"form.firstName"}],staticClass:"form-control",attrs:{"type":"text","id":"first_name","maxlength":"30"},domProps:{"value":(_vm.form.firstName)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "firstName", $event.target.value)}}})]}}])})],1),_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"name":"Last Name","rules":{
                required: true,
                regex: /^[ÑA-Za-z][ñA-Za-z- ]{1,30}$/,
              }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('label',{attrs:{"for":"last_name"}},[_vm._v("Last Name")]),_c('span',{staticClass:"form-text text-danger label-hint"},[_vm._v(_vm._s(errors[0]))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.lastName),expression:"form.lastName"}],staticClass:"form-control",attrs:{"type":"text","id":"last_name","maxlength":"30"},domProps:{"value":(_vm.form.lastName)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "lastName", $event.target.value)}}})]}}])})],1)]),_c('div',{staticClass:"row mb-3"},[_c('div',{staticClass:"col-md-12"},[_c('ValidationProvider',{attrs:{"name":"Address","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('label',{attrs:{"for":"address"}},[_vm._v("Address")]),_c('span',{staticClass:"form-text text-danger label-hint"},[_vm._v(_vm._s(errors[0]))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.address),expression:"form.address"}],staticClass:"form-control",attrs:{"type":"text","id":"address"},domProps:{"value":(_vm.form.address)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "address", $event.target.value)}}})]}}])})],1)]),_c('div',{staticClass:"row mb-3"},[_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"name":"Contact No","rules":{ required: true, max: 11, regex: /^09[0-9]{9}$/ }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('label',{attrs:{"for":"phone_number"}},[_vm._v("Contact No")]),_c('span',{staticClass:"form-text text-danger label-hint"},[_vm._v(_vm._s(errors[0]))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.contactNo),expression:"form.contactNo"}],staticClass:"form-control",attrs:{"type":"text","id":"phone_number"},domProps:{"value":(_vm.form.contactNo)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "contactNo", $event.target.value)}}}),_c('span',{staticClass:"form-text label-hint-small mt-1"},[_vm._v("11-digit number, i.e. 0905xxxxxx")])]}}])})],1),_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"vid":"email","name":"Email","rules":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('label',{attrs:{"for":"email_address"}},[_vm._v("Email Address")]),_c('span',{staticClass:"form-text text-danger label-hint"},[_vm._v(_vm._s(errors[0]))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.email),expression:"form.email"}],staticClass:"form-control",attrs:{"type":"email","id":"email_address","maxlength":"50"},domProps:{"value":(_vm.form.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "email", $event.target.value)}}}),_c('span',{staticClass:"form-text label-hint-small mt-1"},[_vm._v("This will become your account's username")])]}}])})],1)]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.form.email && _vm.form.email.length > 0),expression:"form.email && form.email.length > 0"}],staticClass:"row mt-2 mb-3"},[_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"vid":"password","name":"Password","rules":{ required: false, max: 6, regex: /^([0-9]{6})$/ }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('label',{attrs:{"for":"password"}},[_vm._v("Password")]),_c('span',{staticClass:"form-text text-danger label-hint"},[_vm._v(_vm._s(errors[0]))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.password),expression:"form.password"}],staticClass:"form-control",attrs:{"type":"password","id":"password"},domProps:{"value":(_vm.form.password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "password", $event.target.value)}}}),_c('span',{staticClass:"form-text label-hint-small mt-1"},[_vm._v("Password must be a 6-digit no.")])]}}])})],1),_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"name":"Confirm Password","rules":{
                required: false,
                max: 6,
                confirmed: 'password',
                regex: /^([0-9]{6})$/,
              }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('label',{attrs:{"for":"confirm_password"}},[_vm._v("Confirm Password")]),_c('span',{staticClass:"form-text text-danger label-hint"},[_vm._v(_vm._s(errors[0]))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.confirmPassword),expression:"confirmPassword"}],staticClass:"form-control",attrs:{"type":"password","id":"confirm_password"},domProps:{"value":(_vm.confirmPassword)},on:{"input":function($event){if($event.target.composing){ return; }_vm.confirmPassword=$event.target.value}}}),_c('span',{staticClass:"form-text label-hint-small mt-1"},[_vm._v("Password must be a 6-digit no.")])]}}])})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"send-button"},[_c('button',{staticClass:"btn btn-block submit-button",on:{"click":_vm.onSubmit}},[_vm._v(" Register ")])])])]),_c('alert-dialog')],1)],1)])]),_c('DownloadQRCode',{attrs:{"newlyCreated":true,"isOpen":_vm.isDownloadDialogOpen,"userDetails":_vm.userDetails},on:{"on-close":function($event){_vm.isDownloadDialogOpen = false}}})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row text-center"},[_c('div',{staticClass:"col-md-12 mb-3"},[_c('h2',{staticClass:"title mb-0"},[_vm._v("eLog User Registration Form")]),_c('h4',{staticClass:"mt-0"},[_vm._v("Let's get started")])])])}]

export { render, staticRenderFns }