export default {
    state: {
        isOpen: false,
        message: '',
        status: 'success' // success or error
    },
    getters: {
        getDialogProps(state) {
            return {
                isOpen: state.isOpen,
                message: state.message,
                status: state.status
            };
        }
    },
    mutations: {
        SHOW_DIALOG(state, params) {
            state.isOpen = true;
            state.message = params.message;
            state.status = params.status;
        },
        CLOSE_DIALOG(state) {
            state.isOpen = false;
            state.message = '';
            state.status = '';
        }
    }
}