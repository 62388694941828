var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"section py-0 my-0",staticStyle:{"background-color":"#faf9ff"},attrs:{"id":"contact-us"}},[_c('div',{staticClass:"container pb-5"},[_c('div',{staticClass:"contact-us shadow round app-brand-color"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6 mt-5 text-white"},[_vm._m(0),_vm._m(1),_vm._m(2),_c('div',{staticClass:"row mt-4"},[_c('div',{staticClass:"col-md-12"},[_vm._m(3),_c('div',{staticStyle:{"padding-left":"63px","width":"260px"}},[_vm._v(" "+_vm._s(_vm.supportEmail)+" ")])])])]),_c('div',{staticClass:"col-md-6 mt-5"},[_c('div',{staticClass:"card"},[_c('loading',{attrs:{"active":_vm.isLoading,"loader":"spinner","color":"#4599C0","is-full-page":false},on:{"update:active":function($event){_vm.isLoading=$event}}}),_c('div',{staticClass:"card-body"},[_c('ValidationObserver',{ref:"form"},[_c('div',{staticClass:"form-group"},[_c('ValidationProvider',{attrs:{"name":"Name","rules":{
                    required: true,
                    regex: _vm.fullNameRegex
                  }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('label',{attrs:{"for":"name"}},[_vm._v("Your Name")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.name),expression:"form.name"}],staticClass:"form-control",attrs:{"type":"text","id":"name"},domProps:{"value":(_vm.form.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "name", $event.target.value)}}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"form-group"},[_c('ValidationProvider',{attrs:{"name":"Email","rules":{
                    required: true,
                    regex: _vm.emailRegex
                  }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('label',{attrs:{"for":"email"}},[_vm._v("Email Address")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.email),expression:"form.email"}],staticClass:"form-control",attrs:{"type":"email","id":"email"},domProps:{"value":(_vm.form.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "email", $event.target.value)}}}),_c('span',{staticClass:"form-text text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"form-group"},[_c('ValidationProvider',{attrs:{"name":"Message","rules":{
                    required: true,
                    regex: _vm.messageRegex
                  }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('label',{attrs:{"for":"message"}},[_vm._v("Your Message")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.message),expression:"form.message"}],staticClass:"form-control",attrs:{"id":"message","rows":"3"},domProps:{"value":(_vm.form.message)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "message", $event.target.value)}}}),_c('span',{staticClass:"form-text text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"form-group text-right"},[_c('button',{staticClass:"btn submit-button",attrs:{"round":""},on:{"click":_vm.onSubmit}},[_vm._v(" Send Message ")])])]),_c('alert-dialog')],1)],1)])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12 text-white"},[_c('h2',{staticClass:"font-weight-bold mb-0"},[_vm._v("Contact Us!")]),_c('h5',[_vm._v("Let us know if you have any questions")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row mt-4"},[_c('div',{staticClass:"col-md-12"},[_c('h3',{staticClass:"mb-0"},[_c('i',{staticClass:"fa fa-map-marker-alt fa-lg mr-3"}),_vm._v(" Find us ")]),_c('div',{staticStyle:{"padding-left":"51px","width":"260px"}},[_vm._v(" Diezmo-Road Brgy. Pulo Cabuyao, Laguna Philippines ")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row mt-4"},[_c('div',{staticClass:"col-md-12"},[_c('h3',{staticClass:"mb-0"},[_c('i',{staticClass:"fa fa-mobile-alt fa-lg mr-3"}),_vm._v(" Give us a ring ")]),_c('div',{staticStyle:{"padding-left":"51px","width":"260px"}},[_vm._v(" 0917 111 8008 ")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('h3',{staticClass:"mb-0"},[_c('i',{staticClass:"fa fa-envelope fa-lg mr-3"}),_vm._v(" Email us ")])}]

export { render, staticRenderFns }